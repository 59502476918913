// import { 
//   debounce
// } from './helpers'
import {
  setClassNameNavbar
} from './navigation'
import {
  showHeaderSupportButton,
  hideHeaderSupportButton
} from './header'
import './tooltip'
import './quote'
import './clients'
import './related'
import './team'
import './forms'
import './share'
import {
  contentReadingProgressbar
} from './content'

// Init


if (window.scrollY > 0) {
  showHeaderSupportButton()
} else {
  hideHeaderSupportButton()
}

// Window scroll
window.addEventListener('scroll', () => {
  setClassNameNavbar()

  if (window.scrollY > 0) {
    showHeaderSupportButton()
  } else {
    hideHeaderSupportButton()
  }

  contentReadingProgressbar()
})

// Debounce window resize
// window.addEventListener('resize', debounce(() => {
  
// }), 100)
