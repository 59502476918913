import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider.js'

function relatedSlider () {
  const slider = document.getElementById('jsSelatedSlider'),
        options = {
          container: '#jsSelatedSlider',
          items: 1,
          gutter: 32,
          responsive: {
            576: {
              items: 2
            },
            992: {
              items: 3
            },
            1440: {
              items: 4
            }
          },
          nav: false,
          controls: true,
          controlsText: ['Vorige', 'Volgende'],
          loop: false
        }
  
  if (slider) {
    const sliderInit = tns(options)
    return sliderInit
  }
}

relatedSlider()
