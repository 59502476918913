function navigationToggle() {
  const navbar = document.getElementById('js-navbar-nav'),
        toggler = document.getElementById('js-navbar-toggler')

  if (navbar) {
    navbar.addEventListener('show.bs.collapse', event => {
      console.log(event)
      toggler.classList.add('is-open')
    })
  
    navbar.addEventListener('hide.bs.collapse', event => {
      toggler.classList.remove('is-open')
    })
  }
}

function setClassNameNavbar() {
  const nav = document.querySelector('#js-navigation')
  if (window.scrollY > 2) {
    nav.classList.add('is-scrolled')
  } else {
    nav.classList.remove('is-scrolled')
  }
}

navigationToggle()

export {
  setClassNameNavbar
}
