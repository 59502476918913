import JustValidate from 'just-validate'
import {
  postFormData,
  isValidEmailAddress
} from '../helpers'

const formConfig = {
  errorsContainer: document.querySelector('#jsFormResult'),
  tooltip: {
    position: 'bottom'
  },
  errorFieldCssClass: 'is-invalid',
  successFieldCssClass: 'is-valid',
  lockForm: true,
  validateBeforeSubmitting: true,
  submitFormAutomatically: false,
  testingMode: false
}

function validateGetInTouchForm() {
  const form = document.querySelector('#jsGetIntouchForm'),
        url = '/imbussleutel/surface/Form/GetInTouch'

  if (form) {
    const validator = new JustValidate('#jsGetIntouchForm', formConfig),
          nohoney = form.getElementById('h_password')
          // console.log(nohoney)

    validator
    .addFlied(
      '#name',
      [
        {
          rule: 'required',
          errorMessage: 'Vul jouw naam in.'
        },
        {
          rule: 'minLength',
          value: 2,
          errorMessage: 'Je naam moet minimaal uit 2 karakters bestaan.'
        },
        {
          rule: 'maxLength',
          value: 55,
          errorMessage: 'Je naam mag maximaal uit 55 karakters bestaan.'
        }
      ],
      {
        errorsContainer: '#jsErrorName'
      }
    )
    .addField(
      '#email',
      [
        {
          rule: 'required',
          errorMessage: 'Vul een e-mailadres in.'
        },
        {
          rule: 'email',
          errorMessage: 'Vul een geldig e-mailadres in.'
        }
      ],
      {
        errorsContainer: '#jsErrorEmail'
      }
    )
    .addField(
      '#company',
      [
        {
          rule: 'required',
          errorMessage: 'Vul je bedrijfsnaam in.'
        },
        {
          rule: 'minLength',
          value: 3,
          errorMessage: ''
        },
        {
          rule: 'maxLength',
          value: 255,
          errorMessage: ''
        }
      ],
      {
        errorsContainer: '#jsErrorCompany'
      }
    )
    .addField(
      '#message',
      [
        {
          rule: 'required',
          errorMessage: 'Wat wil je ons laten weten of vragen?'
        }
      ],
      {
        errorsContainer: '#jsErrorMessage'
      }
    )
    .onSuccess(() => {

    })
  }
}
validateGetInTouchForm()

function validateContactForm() {
  const form = document.querySelector('#jsContactForm'),
        url = '/imbussleutel/surface/Form/SendContactFormMail'

  if (form) {
    const validator = new JustValidate('#jsContactForm', formConfig)

    validator
    .addField(
      '#name',
      [
        {
          rule: 'required',
          errorMessage: 'Vul jouw naam in.'
        },
        {
          rule: 'minLength',
          value: 2,
          errorMessage: 'Je naam moet minimaal uit 2 karakters bestaan.'
        },
        {
          rule: 'maxLength',
          value: 55,
          errorMessage: 'Je naam mag maximaal uit 55 karakters bestaan.'
        }
      ],
      {
        errorsContainer: '#jsErrorName'
      }
    )
    .addField(
      '#email',
      [
        {
          rule: 'required',
          errorMessage: 'Vul een e-mailadres in.'
        },
        {
          rule: 'email',
          errorMessage: 'Vul een geldig e-mailadres in.'
        }
      ],
      {
        errorsContainer: '#jsErrorEmail'
      }
    )
    .addField(
      '#phone',
      [
        {
          rule: 'required',
          errorMessage: 'Vul jouw telefoonnummer in.'
        },
        {
          rule: 'number',
          errorMessage: 'Je telefoonnummer moet uit cijfers bestaan.'
        },
        {
          rule: 'minLength',
          value: 10,
          errorMessage: 'Je telefoonnummer moet minimaal uit 10 cijfers bestaan.'
        },
        {
          rule: 'maxLength',
          value: 10,
          errorMessage: 'Je telefoonnummer moet maximaal uit 10 cijfers bestaan.'
        }
      ],
      {
        errorsContainer: '#jsErrorPhone'
      }
    )
    .onSuccess(() => {
      
    })
  }
}

function validateSupportForm() {
  const form = document.querySelector('#jsSupportForm'),
        url = '/imbussleutel/surface/Form/SendSupportFormMail'

  if (form) {
    const validator = new JustValidate('#jsSupportForm', formConfig)

    validator
    .addField(
      '#name',
      [
        {
          rule: 'required',
          errorMessage: 'Vul jouw voornaam in.'
        },
        {
          rule: 'minLength',
          value: 2,
          errorMessage: 'Je voornaam moet minimaal uit 2 karakters bestaan.'
        },
        {
          rule: 'maxLength',
          value: 55,
          errorMessage: 'Je voornaam mag maximaal uit 55 karakters bestaan.'
        }
      ],
      {
        errorsContainer: '#jsErrorName'
      }
    )
    .addField(
      '#email',
      [
        {
          rule: 'required',
          errorMessage: 'Vul een e-mailadres in.'
        },
        {
          rule: 'email',
          errorMessage: 'Vul een geldig e-mailadres in.'
        }
      ],
      {
        errorsContainer: '#jsErrorEmail'
      }
    )
    .addField(
      '#phone',
      [
        {
          rule: 'required',
          errorMessage: 'Vul jouw telefoonnummer in.'
        },
        {
          rule: 'number',
          errorMessage: 'Je telefoonnummer moet uit cijfers bestaan.'
        },
        {
          rule: 'minLength',
          value: 10,
          errorMessage: 'Je telefoonnummer moet minimaal uit 10 cijfers bestaan.'
        },
        {
          rule: 'maxLength',
          value: 10,
          errorMessage: 'Je telefoonnummer moet maximaal uit 10 cijfers bestaan.'
        }
      ],
      {
        errorsContainer: '#jsErrorPhone'
      }
    )
    .addField(
      '#company',
      [
        {
          rule: 'required',
          errorMessage: 'Vul je bedrijfsnaam in.'
        },
        {
          rule: 'minLength',
          value: 3,
          errorMessage: ''
        },
        {
          rule: 'maxLength',
          value: 255,
          errorMessage: ''
        }
      ],
      {
        errorsContainer: '#jsErrorCompany'
      }
    )
    .onSuccess(() => {
      
    })
  }
}

function validateApplyForm() {
  const validate = document.querySelector('#jsApplyForm'),
        url = ''

  if (form) {
    const validator = new JustValidate('#jsApplyForm', formConfig)

    validator
    .addField(
      '#fname',
      [
        {
          rule: 'required',
          errorMessage: 'Vul jouw voornaam in.'
        },
        {
          rule: 'minLength',
          value: 2,
          errorMessage: 'Je voornaam moet minimaal uit 2 karakters bestaan.'
        },
        {
          rule: 'maxLength',
          value: 55,
          errorMessage: 'Je voornaam mag maximaal uit 55 karakters bestaan.'
        }
      ],
      {
        errorsContainer: '#jsErrorFName'
      }
    )
    .addField(
      '#lname',
      [
        {
          rule: 'required',
          errorMessage: 'Vul jouw achternaam in.'
        },
        {
          rule: 'minLength',
          value: 2,
          errorMessage: 'Je achternaam moet minimaal uit 2 karakters bestaan.'
        },
        {
          rule: 'maxLength',
          value: 55,
          errorMessage: 'Je achternaam mag maximaal uit 55 karakters bestaan.'
        }
      ],
      {
        errorsContainer: '#jsErrorLName'
      }
    )
    .addField(
      '#email',
      [
        {
          rule: 'required',
          errorMessage: 'Vul een e-mailadres in.'
        },
        {
          rule: 'email',
          errorMessage: 'Vul een geldig e-mailadres in.'
        }
      ],
      {
        errorsContainer: '#jsErrorEmail'
      }
    )
    .addField(
      '#phone',
      [
        {
          rule: 'required',
          errorMessage: 'Vul jouw telefoonnummer in.'
        },
        {
          rule: 'number',
          errorMessage: 'Je telefoonnummer moet uit cijfers bestaan.'
        },
        {
          rule: 'minLength',
          value: 10,
          errorMessage: 'Je telefoonnummer moet minimaal uit 10 cijfers bestaan.'
        },
        {
          rule: 'maxLength',
          value: 10,
          errorMessage: 'Je telefoonnummer moet maximaal uit 10 cijfers bestaan.'
        }
      ],
      {
        errorsContainer: '#jsErrorPhone'
      }
    )
    .addField(
      '#cv',
      [
        {
          rule: 'required',
          errorMessage: 'Voeg je CV toe.'
        },
        {
          rule: 'files',
          value: {
            files: {
              extensions: ['pdf', 'docx'],
              maxSize: 7168000,
              types: ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
            }
          },
          errorMessage: ''
        },
        {
          rule: 'minFilesCount',
          value: 1,
          errorMessage: ''
        },
        {
          rule: 'maxFilesCount',
          value: 1,
          errorMessage: ''
        }
      ],
      {
        errorsContainer: '#jsErrorCv'
      }
    )
    .addField(
      '#letter',
      [
        {
          rule: 'required',
          errorMessage: 'Voeg je motivstiebrief toe.'
        },
        {
          rule: 'files',
          value: {
            files: {
              extensions: ['pdf', 'docx'],
              maxSize: 7168000,
              types: ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
            }
          },
          errorMessage: ''
        },
        {
          rule: 'minFilesCount',
          value: 1,
          errorMessage: ''
        },
        {
          rule: 'maxFilesCount',
          value: 1,
          errorMessage: ''
        }
      ],
      {
        errorsContainer: '#jsErrorLetter'
      }
    )
    .onSuccess(() => {
      
    })
  }
}

function validateNewsletterForm() {
  const newsletterForm = document.querySelector('#jsNewsletterForm')

  if (newsletterForm) {
    const checkBox = document.querySelector('#jsCheckboxPrivacy'),
          resultMessage = document.querySelector('#jsResultMessage')

    newsletterForm.addEventListener('submit', (e) => {
      e.preventDefault()
      const email = document.querySelector('#jsNewsletterEmailInput').value,
            label = document.querySelector('.forms__checkbox-label'),
            input = document.querySelector('#jsNewsletterEmailInput')

      if (!checkBox.checked) {
        label.classList.add('is-invalid')
      } else if (email === '' || !isValidEmailAddress(email)) {
        label.classList.remove('is-invalid')
        input.classList.add('is-invalid')
      } else {
        resultMessage.textContent = subscribe(email, false)
      }
    })
  }
}

function subscribe(emailAddress, isContactForm) {
  const url = '/imbussleutel/surface/Subscription/Subscribe'

  fetch(url, {
    method: 'post',
    body: emailAddress
  }).then(response => response.json)

  if (isContactForm) {
    const responseText = document.querySelector('#jsResultMessageContactForm').textContent + '<br><br>' 
  } else {
    document.querySelector('#jsNewsletterForm').classList.remove('d-lg-flex')
    document.querySelector('#jsNewsletterForm').classList.add('d-none')
    document.querySelector('#jsNewsletterResult').classList.remove('d-none')
  }
}
