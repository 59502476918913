function clickShareButtons () {
  const xBtn = document.querySelector('[data-share-x]'),
        linkedinBtn = document.querySelector('[data-share-linkedin]')

  if (xBtn) {
    xBtn.addEventListener('click', () => {
      const url = encodeURIComponent(window.location.href),
            text = encodeURIComponent(document.title),
            hashtags = ['xuntos','denhaag','webdevelopment','umbraco']
            xUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}&hashtags=${hashtags}`
            window.open(xUrl, '_blank')
    })
  }

  if (linkedinBtn) {
    linkedinBtn.addEventListener('click', () => {
      const url = encodeURIComponent(window.location.href),
            linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
            window.open(linkedinUrl, '_blank')
    })
  }
}

clickShareButtons()
