import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider.js'

function clientsSlider() {
  const slider = document.getElementById('jsClientsSlider'),
        options = {
          container: '#jsClientsSlider',
          items: 3,
          responsive: {
            576: {
              items: 4
            },
            992: {
              items: 5
            },
            1200: {
              items: 6
            }
          },
          nav: false,
          controls: true,
          controlsText: ['Vorige', 'Volgende']
        }

  if (slider) {
    const sliderInit = tns(options)
    return sliderInit
  }
}

clientsSlider()
