import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider.js'

function teamSlider () {
  const slider = document.getElementById('jsTeamSlider'),
        options = {
          container: '#jsTeamSlider',
          items: 2,
          responsive: {
            768: {
              items: 3
            },
            1200: {
              items: 4
            }
          },
          nav: false,
          controls: true,
          controlsText: ['Vorige', 'Volgende'],
          loop: false
        }

  if (slider) {
    const sliderInit = tns(options)
    return sliderInit
  }
}

teamSlider()
