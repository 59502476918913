function contentReadingProgressbar () {
  const bar = document.querySelector('[data-reading-progressbar]')

  if (bar) {
    const docElem = document.documentElement, 
		      docBody = document.body
		let scrollTop = docElem['scrollTop'] || docBody['scrollTop'],
        scrollBottom = (docElem['scrollHeight'] || docBody['scrollHeight']) - window.innerHeight,
		    scrollPercent = scrollTop / scrollBottom * 100 + '%'
	
    bar.style.setProperty('--scrollAmount', scrollPercent);
  }
}

export {
  contentReadingProgressbar
}
