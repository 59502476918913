import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider.js'

function quoteSlider() {
  const slider = document.getElementById('jsQuoteSlider'),
        options = {
          container: '#jsQuoteSlider',
          items: 1,
          nav: false,
          controls: true,
          controlsText: ['Vorige', 'Volgende']
        }

  if (slider) {
    const sliderInit = tns(options)
    return sliderInit
  }
}

quoteSlider()
